import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const Condition = ({ condition, path, image }) => {
  return (
    <Link to={path}>
      <div className="group relative w-56 h-56 flex flex-col justify-center items-center border border-white cursor-pointer bg-center">
        <Img
          className="w-full h-full absolute"
          imgStyle={{ objectFit: 'cover' }}
          fluid={image}
          alt="condition"
        />
        <p className="text-white text-lg z-10">{condition}</p>
        <p className="text-white text-md z-10 group-hover:underline">
          more info
        </p>
        <div className="absolute group-hover:opacity-25 w-full h-full bg-black opacity-50"></div>
      </div>
    </Link>
  );
};

export default Condition;
