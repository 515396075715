import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Condition from './Condition';

const conditionsMap = {
  'back-pain': { condition: 'Back Pain', path: '/services/back-pain' },
  arthritis: { condition: 'Arthritis', path: '/services/arthritis' },
  contraception: {
    condition: 'Contraception',
    path: '/services/contraception'
  },
  dermatology: { condition: 'Dermatology', path: '/services/dermatology' },
  diabetes: { condition: 'Diabetes', path: '/services/diabetes' },
  hypertension: { condition: 'Hypertension', path: '/services/hypertension' },
  immunization: { condition: 'Immunization', path: '/services/immunizations' },
  'preventative-care': {
    condition: 'Preventative Care',
    path: '/services/preventative-care'
  },
  'primary-care': {
    condition: 'Primary Care',
    path: '/services/primary-care'
  },
  'sleep-disorder': {
    condition: 'Sleep Disorders',
    path: '/services/sleep-disorders'
  },
  'weight-loss': {
    condition: 'Weight Loss',
    path: '/services/weight-loss'
  },
  'well-woman-exam': {
    condition: 'Well Woman Exam',
    path: '/services/well-woman-exam'
  },
  add: {
    condition: 'ADD',
    path: '/services/adhd'
  },
  asthma: { condition: 'Asthma', path: '/services/asthma' },
  'heart-disease': {
    condition: 'Heart Disease',
    path: '/services/heart-disease'
  },
  'high-cholesterol': {
    condition: 'High Cholesterol',
    path: '/services/high-cholesterol'
  },
  'wellness-exam': {
    condition: 'Wellness Exams',
    path: '/services/wellness-exams'
  },
  'thyroid-disease': {
    condition: 'Thyroid Issues',
    path: '/services/thyroid-issues'
  },
  'geriatric-medicine': {
    condition: 'Geriatric Medicine',
    path: '/services/geriatric-medicine'
  },
  'depression-anxiety': {
    condition: 'Depression & Anxiety',
    path: '/services/depression-anxiety'
  }
};

const Conditions = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "conditions" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <div className="py-16 w-full flex flex-col justify-center items-center bg-sunstate-blue border-b">
      <p className="text-xl text-white uppercase font-semibold">Who we help</p>
      <p className="text-4xl text-white mb-8 text-center">
        Conditions & Treatments
      </p>
      <div className="w-4/5 flex flex-row flex-wrap justify-center items-center">
        {data.allFile.edges.map((item, index) => (
          <Condition
            key={index}
            condition={conditionsMap[item.node.name].condition}
            path={conditionsMap[item.node.name].path}
            image={item.node.childImageSharp.fluid}
          />
        ))}
      </div>
    </div>
  );
};

export default Conditions;
